import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { getNode } from '../utils/graphql';

export const query = graphql`
  query ErrorPage($locale: String) {
    prismic {
      allError404s(lang: $locale) {
        edges {
          node {
            title
          }
        }
      }
    }
  }
`;

const ErrorPage = ({ data, pageName }) => {
  const node = getNode(data, pageName);

  return (
    <RichText render={node.title} />
  );
};

ErrorPage.defaultProps = {
  pageName: 'error404',
};

export default ErrorPage;
